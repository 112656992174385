<template>
    <div v-loading="loading" class="info-list">
        <Header/>
        <div class="blank20"/>
        <div class="main-page-content contain-left-right">
            <div class="left-content">
                <h3 class="titleh3 selecth3">模拟实操</h3>
                <div class="blank20"/>
                <div v-for="(item, index) in list" :key="index" v-html="item.content" class="content_con"> 
                </div>
            </div>
            <RightContent />
        </div>
        <div class="blank20"/>
        <Footer/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: mapState([
        'richTextObj'
    ]),
    data() {
        return {
            loading:false,
            list:[],
        }
    },
    components:{
    },
    beforeMount() {
        this.init();
    },
    methods: {
        async init(){
            this.loading = true;
            const res = await this.$http.get('/api/exam/getOperateList');
            this.loading = false;
            if(res){
                this.list = res;
            }
        }
    },
}
</script>
<style lang="scss" scoped>

</style>
